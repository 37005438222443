
import style from './style.module.css'
import HeaderAppBar from '@root/components/organisms/Header/Component'
import React from 'react'
import { hasValue } from '@root/misc/helpers'
import { Modal, Box, Stack, Typography, Button, Card, CircularProgress } from '@mui/material'
import { useAppContext } from '@root/global/context'
import { modalStyle, ProgressStateAdm, ProgressStateDtg, ProgressStateGbd } from '@root/misc/constants'
import { isMobile } from 'react-device-detect'
import { Trans } from '@lingui/macro'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useWindowSize from '@root/misc/use-window-dimensions'
import screenfull from 'screenfull'
import { useLocalStorage } from 'usehooks-ts'
import axios from 'axios'
import { getApiUrl } from '@root/misc/variables'

interface Props {
  children?: React.ReactNode
  type: 'home' | 'adm' | 'dtg' | 'gbd' | 'vda' | 'introduction'
}

enum Result {
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export function Layout (props: Props): JSX.Element {
  const [loading, setLoading] = React.useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, setResult] = React.useState<Result>(Result.PENDING)
  const location = useLocation()

  const { variant } = useParams()
  const isValidURL = variant === 'on-location' || variant === 'home' || ['introduction', 'vda'].includes(props.type)

  const [isLeftPedalF8, setIsLeftPedalF8] = useLocalStorage<string | null>('isLeftPedalF8', null)

  const navigate = useNavigate()
  const windowSize = useWindowSize()
  const [isTooSmall, setIsTooSmall] = React.useState<boolean>(false)

  // q: what does this useEffect function do?
  // a: it posts the token to the backend to check if it is valid
  React.useEffect(() => {
    const postData = async (): Promise<void> => {
      const postData = {
        token,
        type: location.pathname.includes('introduction') ? 'Introductie' : location.pathname.slice(1, 4),
        location: variant
      }
      await axios.post(getApiUrl(), JSON.stringify(postData))
        .then(response => {
          if (response.data.token_valid as boolean) {
            setLoading(false)
            setResult(Result.SUCCESS)
          } else {
            if (!(response.data.token_valid as boolean)) {
              setLoading(false)
              setResult(Result.ERROR)
            }
          }
        })
        .catch(error => {
          setLoading(false)
          setResult(Result.ERROR)
          console.error(error)
        })
    }

    void postData()
  }, [])

  React.useEffect(() => {
    setIsTooSmall((windowSize.height ?? 0) < 600 || (windowSize.width ?? 0) < 800)
  }, [windowSize])

  const {
    progressStateAdm,
    progressStateDtg,
    progressStateGbd,
    setProgressStateAdm,
    setProgressStateDtg,
    setProgressStateGbd
  } = useAppContext()

  React.useEffect(() => {
    if (hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production' && isValidURL) {
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        handleOpen()
      }
    }
  }, [])

  // We demand the test to be taken in fullscreen mode
  // q: what does this following function do?
  // a: it checks if the user is in fullscreen mode and if not, it opens the modal
  if (hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') {
    screenfull.on('change', (event) => {
      if (screenfull.isEnabled && !screenfull.isFullscreen) {
        // We’re exiting fullscreen
        if (
          progressStateAdm !== ProgressStateAdm.End &&
          progressStateDtg !== ProgressStateDtg.End &&
          progressStateGbd !== ProgressStateGbd.End
        ) {
          setProgressStateAdm(ProgressStateAdm.Introduction)
          setProgressStateDtg(ProgressStateDtg.Introduction)
          setProgressStateGbd(ProgressStateGbd.Introduction)
          handleOpen()
        }
      }
    })
  }

  const modalBtnRef = React.useRef<HTMLButtonElement>(null)

  const [open, setOpen] = React.useState(false)
  const handleOpen = (): void => {
    setOpen(true)
    setTimeout(() => {
      modalBtnRef.current?.focus()
    }, 100)
  }

  const handleClose = (): void => {
    screenfull.isEnabled && screenfull.request()
    setOpen(false)
  }

  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')
  const calibrate = urlParams.get('calibrate')

  const { setCallBackUrl } = useAppContext()

  // q: what does this following useEffect do?
  // a: it checks if the user is on location and if so, it redirects the user to the introduction page
  React.useEffect(() => {
    const searchParams = location.search.replace('?calibrate=true', '').replace('&calibrate=true', '')
    if (hasValue(calibrate) && calibrate === 'true') {
      setIsLeftPedalF8(null)
    }

    const areOnLocation = location.pathname.includes('on-location')
    setCallBackUrl(`${location.pathname}${searchParams}`)
    if (areOnLocation && !hasValue(isLeftPedalF8)) {
      navigate(`/introduction${searchParams}`)
    }
  }, [])

  if (loading) {
    return <Box sx={{ padding: 4, paddingBlockStart: 10, display: 'grid', placeItems: 'center' }}><CircularProgress /></Box>
  }

  // Mobile is currently unavailable
  if (isMobile || isTooSmall) {
    return (
      <Stack alignItems='center' justifyContent='center' sx={{ height: '100vh', width: '100vw' }}>
        <Card sx={{ m: 4, p: 4 }}>
          <Typography variant='h4' component='h2' sx={{ mb: 2 }}>
            <Trans id='mobile.title'>
              Niet beschikbaar op dit apparaat
            </Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans id='mobile.message'>
              Deze tests zijn uitsluitend beschikbaar op desktop-pcs en laptops met toetsenbord en muis.
            </Trans>
          </Typography>
        </Card>
      </Stack>
    )
  }

  if (!hasValue(token) || result === Result.ERROR) {
    return (
      <Stack alignItems='center' justifyContent='center' sx={{ height: '100vh', width: '100vw' }}>
        <Card sx={{ m: 4, p: 4 }}>
          <Typography variant='h4' component='h2' sx={{ mb: 2 }}>
            <Trans id='token.title'>
              Ongeldig token!
            </Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans id='token.message'>
              Deze tests zijn uitsluitend beschikbaar met een geldig token.
            </Trans>
          </Typography>
        </Card>
      </Stack>
    )
  }

  if (!isValidURL) {
    return (
      <Stack alignItems='center' justifyContent='center' sx={{ height: '100vh', width: '100vw' }}>
        <Card sx={{ m: 4, p: 4 }}>
          <Typography variant='h4' component='h2' sx={{ mb: 2 }}>
            <Trans id='invalidurl.title'>
              Ongeldige URL!
            </Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans id='invalidurl.message'>
              De URL moet uitwijzen of de test op locatie of thuis wordt afgenomen.
            </Trans>
          </Typography>
        </Card>
      </Stack>
    )
  }

  return (
    <>
      <Modal
        disableAutoFocus
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Box sx={modalStyle}>
          <Stack
            direction='column'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
            sx={{ height: '100%' }}
          >
            <div>
              <Typography id='modal-title' variant='h6' component='h2'>
                <Trans id='fullscreen.title'>
                  Ter voorbereiding
                </Trans>
              </Typography>
              <Typography id='modal-description' sx={{ mt: 2 }}>
                <Trans id='fullscreen.message'>
                  De test kan het beste worden voltooid zonder afleiders. Daarom vragen we je de test fullscreen af te nemen.
                </Trans>
              </Typography>
            </div>
            <Button
              color='primary'
              ref={modalBtnRef}
              variant='contained'
              onClick={() => handleClose()}
            >
              <Trans id='fullscreen.button'>Ga fullscreen</Trans>
            </Button>
          </Stack>
        </Box>
      </Modal>

      <div>
        <HeaderAppBar />
        <div className={style.wrapper}>
          <div className={style['children-wrapper']}>
            {props.children}
          </div>
        </div>

        {!(hasValue(process.env.NODE_ENV) && process.env.NODE_ENV === 'production') && (
          <div className={style.debug}>
            <nav>
              <ul>
                <li>
                  <a href='/introduction?token=dit-is-een-token'>Introductie</a>
                </li>
                <li>
                  <a href='/gbd/home?token=dit-is-een-token'>Gbd (Thuis)</a>
                </li>
                <li>
                  <a href='/gbd/home/4?token=dit-is-een-token'>Gbd - 4 pagina's (Thuis)</a>
                </li>
                <li>
                  <a href='/gbd/overview?token=dit-is-een-token'>Gbd (Overview)</a>
                </li>
                <li>
                  <a href='/gbd/on-location?token=dit-is-een-token'>Gbd(Op locatie)</a>
                </li>
                <li>
                  <a href='/adm/home?token=dit-is-een-token'>Adm (Thuis)</a>
                </li>
                <li>
                  <a href='/adm/on-location?token=dit-is-een-token'>Adm (Op locatie)</a>
                </li>
                <li>
                  <a href='/dtg/home?token=dit-is-een-token'>Dtg (Thuis)</a>
                </li>
                <li>
                  <a href='/dtg/on-location?token=dit-is-een-token'>Dtg (Op locatie)</a>
                </li>
                <li>
                  <a href='/dtg/home/10;10;10/1100;1000;800/20?token=dit-is-een-token'>Dtg (Thuis, custom invoer)</a>
                </li>

              </ul>
            </nav>
          </div>
        )}
      </div>
    </>
  )
}
