import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { nl } from 'make-plural/plurals'

import { messages as messagesNl } from '@locales/nl/messages.js'
import { messages as messagesEn } from '@locales/en/messages.js'

interface Props {
  children?: React.ReactNode
}

export default function I18nWrapper (props: Props): JSX.Element {
  const locale = new URLSearchParams(window.location.search).get('lang')
  switch (locale) {
    case 'en':
      i18n.load('en', messagesEn)
      i18n.activate('en')
      break
    case 'nl':
    default:
      i18n.loadLocaleData({
        nl: { plurals: nl }
      })
      i18n.load('nl', messagesNl)
      i18n.activate('nl')
      break
  }

  return (
    <I18nProvider i18n={i18n}>
      {props.children}
    </I18nProvider>
  )
}
